import { Status } from '../types/status';
export var buildResult = function (event, code, message) {
  if (code === void 0) {
    code = 0;
  }
  if (message === void 0) {
    message = Status.Unknown;
  }
  return {
    event: event,
    code: code,
    message: message
  };
};
