import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core'
import { NavigationService } from '../services/navigation.service'
import { NavigationNode, NavigationTree } from '../types/navigation'
import { UserAvatarComponent } from '../components/user-avatar/user-avatar.component'
import { ThemeSwitcherComponent } from '../components/theme-switcher/theme-switcher.component'
import { NavigationNodeComponent } from '../components/navigation-node/navigation-node.component'
import { NavigationBarService } from '../services/navigation-bar.service'
import { NavModeToggleComponent } from '../components/nav-mode-toggle/nav-mode-toggle.component'
import { AccessService } from '../../../legacy/core/services/access-service.service'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Observable, Subject, take, takeUntil } from 'rxjs'
import { ThemeSwitcherService } from '../../../legacy/core/services/theme-switcher.service'
import { CoreModule } from '../../../legacy/core/core.module'
import { ManageLoggedUser } from '../../../legacy/authentication/services/manage-logged-user.service'
import { NewAffiliateService } from '../../../legacy/core/services/new-affiliate.service'

@Component({
    selector: 'app-navigation-bar',
    standalone: true,
    imports: [UserAvatarComponent, ThemeSwitcherComponent, NavigationNodeComponent, NavModeToggleComponent, CoreModule],
    templateUrl: './navigation-bar.component.html',
    styleUrl: './navigation-bar.component.scss',
    host: {
        '[class.expanded-nav]': 'isExpanded()',
        '[class.simple-nav]': '!isExpanded()',
    },
})
export class NavigationBarComponent implements OnInit, OnDestroy {
    navigationService = inject(NavigationService)
    navBarService = inject(NavigationBarService)
    permissionService = inject(AccessService)
    loggedUserService = inject(ManageLoggedUser)
    router = inject(Router)
    activatedRoute = inject(ActivatedRoute)
    themeService = inject(ThemeSwitcherService)
    affiliateService = inject(NewAffiliateService)

    navigationNodes: NavigationNode[] = []
    settingsNode!: NavigationNode
    notificationNode: NavigationNode = {
        label: 'Notificação',
        isRoot: true,
        nodes: [],
    }

    isExpanded = signal<boolean>(false)
    isDark = signal(false)
    navBarState = 'simple-nav'

    private _onDestroy$ = new Subject()

    ngOnInit(): void {
        this.observeTheme()
        this.navigationNodes = this.filterNodesByUserPermissions(this.navigationService.getNavigationTree().nodes)

        this.observeUserModules()

        this.navBarService.initializeAvailableNavigationNodes(this.navigationNodes)
        this.initSettingsNode()
        this.initializeCurrenteActiveRootNode()
        this.observeRootNodesVisibility()
    }

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }

    private observeUserModules() {
        this.loggedUserService
            .observeUserModules()
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(modules => {
                if (modules && this.permissionService.isExternalUser()) {
                    this.navigationNodes = this.filterNodesByUserModules(this.navigationNodes)
                }
            })
    }

    private observeTheme() {
        this.themeService.isDarkModeEnabled$.pipe(takeUntil(this._onDestroy$)).subscribe(isDarkeMode => {
            this.isDark.set(isDarkeMode)
        })
    }

    private observeRootNodesVisibility() {
        this.navBarService.onChangeRootNodesVisibility$.pipe(takeUntil(this._onDestroy$)).subscribe(visbileNodes => {
            this.navigationNodes = this.filterNodesByUserPermissions(this.setVisibleRootNodes(this.navigationNodes, visbileNodes))
        })
    }

    private initializeCurrenteActiveRootNode() {
        this.navBarService.setActiveNode(this.navBarService.getNodeByUrl(window.location.hash) || null)
    }

    private initSettingsNode() {
        this.settingsNode = this.navigationNodes.find(node => node.label === 'Configurações') || {
            label: 'None',
            isRoot: false,
            nodes: [],
        }
    }

    private filterNodesByUserPermissions(nodes: NavigationNode[]): NavigationNode[] {
        return nodes
            .filter(node => this.permissionService.hasRoles(node.requiredPermissions || []))
            .map(node => ({
                ...node,
                nodes: this.filterNodesByUserPermissions(node.nodes),
            }))
    }

    private filterNodesByUserModules(nodes: NavigationNode[]): NavigationNode[] {
        return nodes
            .filter(
                node =>
                    !this.permissionService.isExternalUser() ||
                    !node.requiredModule ||
                    node.requiredModule.some(module => this.permissionService.hasModule(module)),
            )
            .map(node => ({
                ...node,
                nodes: this.filterNodesByUserModules(node.nodes),
            }))
    }

    toggleNavigationMode() {
        this.isExpanded.set(!this.isExpanded())
        this.navBarService.toggleSimplifiedNavigation(!this.isExpanded())

        if (this.isExpanded()) {
            this.navBarService.clearFocusedRootNode()
        }
    }

    handleNavigate(node: NavigationNode) {
        if (!this.isExpanded()) {
            this.handleClearSimpleNavigationFocusedNode()
        }

        const { url } = node
        if (url) {
            this.navigationService.navigateTo(url)
            this.navBarService.setActiveNode(node)
        }
    }

    handleSimpleNavigationActiveNode(node: NavigationNode) {
        if (!this.isExpanded()) {
            this.navBarService.setFocusedRootNode(node)
        }
    }

    handleNotificationsFocus() {
        this.navBarService.setFocusedRootNode(this.notificationNode)
    }

    hndleClearNotificationFocus() {
        this.navBarService.clearFocusedRootNode()
    }

    handleClearSimpleNavigationFocusedNode() {
        if (!this.isExpanded()) {
            this.navBarService.clearFocusedRootNode()
        }
    }

    handleWelcomeNavBar() {
        const { loggedUser } = this.loggedUserService.getLoggedAccountUser()
        if (loggedUser.partner_id == 'none') return

        if (loggedUser.self_service_welcome && !loggedUser.account_user_terms_accepted) {
            this.navigationNodes = this.setVisibleRootNodes(this.navigationNodes, ['Home', 'Configurações'])
            return
        }
    }

    setVisibleRootNodes(nodes: NavigationNode[], visbileNodes: string[]) {
        if (visbileNodes.length === 0) return nodes

        return nodes.filter(node => node.isRoot && visbileNodes.includes(node.label))
    }
}
