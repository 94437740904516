import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { ManageLoggedUser } from '../../../legacy/authentication/services/manage-logged-user.service'
import { AccessService } from '../../../legacy/core/services/access-service.service'

export const welcomeRouteGuard: CanActivateFn = (route, state) => {
    const loggedUserService = inject(ManageLoggedUser)
    const accessService = inject(AccessService)
    const router = inject(Router)

    if (loggedUserService.isLoggedIn()) {
        const hasPermission = accessService.hasRoles([
            'list-potential-partners',
            'move-potential-partner-phase',
            'move-potential-partner-phase-external',
        ])

        if (!hasPermission) {
            router.navigate(['/not-authorized'])
        }

        return hasPermission
    }

    router.navigate(['/'])
    return false
}
