var n = "function",
  t = "string",
  e = "undefined",
  r = "boolean",
  o = "object",
  u = "array",
  i = "number",
  c = "symbol",
  a = "null",
  f = "error",
  s = "typeError",
  l = "syntaxError",
  d = "asyncFunction",
  p = "generatorFunction",
  y = "asyncGeneratorFunction",
  g = function () {},
  b = "any",
  m = "*",
  v = "none",
  h = "hidden",
  j = "__",
  O = "form",
  S = "input",
  A = "button",
  E = "select",
  N = "change",
  w = "submit",
  D = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/,
  z = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  Z = /^\{[\s\S]*\}$|^\[[\s\S]*\]$/,
  F = "undefined" != typeof process ? process : {},
  P = F.env && F.env.NODE_ENV || "",
  x = "production" === P,
  C = "staging" === P,
  L = "development" === P,
  $ = "undefined" != typeof document,
  T = $ && "localhost" === window.location.hostname,
  _ = null != F.versions && null != F.versions.node,
  k = "undefined" != typeof Deno && void 0 !== Deno.core,
  B = "object" == typeof self && self.constructor && "DedicatedWorkerGlobalScope" === self.constructor.name,
  G = $ && "nodejs" === window.name || "undefined" != typeof navigator && void 0 !== navigator.userAgent && (navigator.userAgent.includes("Node.js") || navigator.userAgent.includes("jsdom"));
function M(n, t) {
  return t.charAt(0)[n]() + t.slice(1);
}
var U = M.bind(null, "toUpperCase"),
  H = M.bind(null, "toLowerCase");
function J(n) {
  return Y(n) ? U("null") : "object" == typeof n ? yn(n) : Object.prototype.toString.call(n).slice(8, -1);
}
function R(n, t) {
  void 0 === t && (t = !0);
  var e = J(n);
  return t ? H(e) : e;
}
function V(n, t) {
  return typeof t === n;
}
var W = V.bind(null, "function"),
  q = V.bind(null, "string"),
  I = V.bind(null, "undefined");
function K(n) {
  return !I(n);
}
var Q = V.bind(null, "boolean"),
  X = V.bind(null, "symbol");
function Y(n) {
  return null === n;
}
function nn(n) {
  return "number" === R(n) && !isNaN(n);
}
function tn(n) {
  return !isNaN(parseFloat(n));
}
function en(n) {
  return !!W(n) && /^class /.test(Function.prototype.toString.call(n));
}
function rn(n) {
  return "array" === R(n);
}
function on(n) {
  if (!un(n)) return !1;
  for (var t = n; null !== Object.getPrototypeOf(t);) t = Object.getPrototypeOf(t);
  return Object.getPrototypeOf(n) === t;
}
function un(n) {
  return n && ("object" == typeof n || null !== n);
}
function cn(n) {
  if (!q(n) || !Z.test(n)) return !1;
  try {
    JSON.parse(n);
  } catch (n) {
    return !1;
  }
  return !0;
}
function an(n) {
  if (Y(n)) return !0;
  switch (typeof n) {
    case "string":
    case "number":
    case "symbol":
    case "undefined":
    case "boolean":
      return !0;
    default:
      return !1;
  }
}
function fn(n, t) {
  return on(n) && W(n[t]);
}
function sn(n) {
  return !!n && !!(!I(Promise) && n instanceof Promise || n.then && W(n.then));
}
function ln(n) {
  return un(n) && W(n.throw) && W(n.return) && W(n.next);
}
function dn(n) {
  return "generatorFunction" === R(n);
}
function pn(n) {
  return "asyncFunction" === R(n);
}
function yn(n) {
  return W(n.constructor) ? n.constructor.name : null;
}
function gn(n) {
  return n instanceof Set;
}
function bn(n) {
  return n instanceof Map;
}
function mn(n) {
  return n instanceof RegExp;
}
function vn(n) {
  return !(!n.constructor || !W(n.constructor.isBuffer)) && n.constructor.isBuffer(n);
}
function hn(n) {
  return n instanceof Error || q(n.message) && n.constructor && nn(n.constructor.stackTraceLimit);
}
function jn(n) {
  return un(n) && q(n.message) && q(n.name);
}
function On(n, t) {
  if ("object" != typeof t || Y(t)) return !1;
  if (t instanceof n) return !0;
  var e = R(new n(""));
  if (hn(t)) for (; t;) {
    if (R(t) === e) return !0;
    t = Object.getPrototypeOf(t);
  }
  return !1;
}
var Sn = On.bind(null, TypeError),
  An = On.bind(null, SyntaxError);
function En(n) {
  if (!W(n)) return !1;
  var t = /{(\r|\n|\s)*}/gm,
    e = g + "";
  return e === (n.toString().match(t) || [""])[0].replace(t, e);
}
function Nn(n) {
  try {
    if (nn(n.length) && W(n.callee)) return !0;
  } catch (n) {
    if (-1 !== n.message.indexOf("callee")) return !0;
  }
  return !1;
}
function wn(n) {
  return !(q(n) && "false" === n.toLowerCase() || !n);
}
function Dn(n) {
  return !n;
}
function zn(n) {
  return !0 === n;
}
function Zn(n) {
  return !1 === n;
}
function Fn(n) {
  return !(n.length > 320) && z.test(n);
}
function Pn(n) {
  return n instanceof Date || W(n.toDateString) && W(n.getDate) && W(n.setDate);
}
function xn(n) {
  return D.test(n);
}
function Cn(n) {
  return !(!Y(n) && (rn(n) ? n.length : gn(n) || bn(n) ? n.size : on(n) && Object.keys(n).length));
}
function Ln(n) {
  return NodeList.prototype.isPrototypeOf(n);
}
function $n(n, t) {
  var e = n instanceof Element || n instanceof HTMLDocument;
  return e && t ? Tn(n, t) : e;
}
function Tn(n, t) {
  return void 0 === t && (t = ""), n && n.nodeName === t.toUpperCase();
}
function _n(n) {
  var t = [].slice.call(arguments, 1);
  return function () {
    return n.apply(void 0, [].slice.call(arguments).concat(t));
  };
}
var kn = _n($n, "form"),
  Bn = _n($n, "button"),
  Gn = _n($n, "input"),
  Mn = _n($n, "select");
function Un(n, t) {
  if (!n || "hidden" === getComputedStyle(n).visibility) return !0;
  for (; n;) {
    if (null != t && n === t) return !1;
    if ("none" === getComputedStyle(n).display) return !0;
    n = n.parentElement;
  }
  return !1;
}
function Hn(n) {
  return n ? rn(n) ? n : [n] : [];
}
export { m as ALL, b as ANY, u as ARRAY, d as ASYNC_FUNCTION, y as ASYNC_GENERATOR_FUNCTION, r as BOOLEAN, A as BUTTON, N as CHANGE, P as ENV, f as ERROR, O as FORM, n as FUNCTION, p as GENERATOR_FUNCTION, h as HIDDEN, S as INPUT, v as NONE, a as NULL, i as NUMBER, o as OBJECT, j as PREFIX, z as REGEX_EMAIL, D as REGEX_ISO, Z as REGEX_JSON, E as SELECT, t as STRING, w as SUBMIT, c as SYMBOL, l as SYNTAX_ERROR, s as TYPE_ERROR, e as UNDEFINED, yn as ctorName, Hn as ensureArray, R as getType, J as getTypeName, Nn as isArguments, rn as isArray, pn as isAsyncFunction, Q as isBoolean, $ as isBrowser, vn as isBuffer, Bn as isButton, en as isClass, Pn as isDate, K as isDefined, k as isDeno, L as isDev, $n as isElement, Fn as isEmail, Cn as isEmpty, hn as isError, jn as isErrorLike, Zn as isFalse, Dn as isFalsy, kn as isForm, W as isFunction, ln as isGenerator, dn as isGeneratorFunction, Un as isHidden, Gn as isInput, xn as isIsoDate, G as isJsDom, cn as isJson, T as isLocalHost, bn as isMap, fn as isMethod, En as isNoOp, _ as isNode, Ln as isNodeList, Tn as isNodeType, Y as isNull, nn as isNumber, tn as isNumberLike, on as isObject, un as isObjectLike, an as isPrimitive, x as isProd, sn as isPromise, mn as isRegex, Mn as isSelect, gn as isSet, C as isStaging, q as isString, X as isSymbol, An as isSyntaxError, zn as isTrue, wn as isTruthy, Sn as isTypeError, I as isUndefined, B as isWebWorker, g as noOp };
