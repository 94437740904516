import { Injectable } from '@angular/core'
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { PartnershipOwnerSetupComponent } from '../../../features/partnership-owner-setup/partnership-owner-setup.component'
import { ManageLoggedUser } from '../../services/manage-logged-user.service'

@Injectable({
    providedIn: 'root',
})
export class PartnershipOwnerSetupGuard implements CanActivate, CanDeactivate<PartnershipOwnerSetupComponent> {
    constructor(private readonly userSessionService: ManageLoggedUser) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const { loggedUser } = this.userSessionService.getLoggedAccountUser()

        const has_permission = loggedUser?.account_user_role_type == 'user-owner-partner' && !loggedUser?.account_user_terms_accepted
        if (!has_permission) return false

        return true
    }

    canDeactivate(
        component: PartnershipOwnerSetupComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot,
        // @ts-ignore
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const { loggedUser } = this.userSessionService.getLoggedAccountUser()

        // @ts-ignore
        if (nextState.url == '/home') return true

        if (!loggedUser.account_user_terms_accepted) return false
    }
}
