import { uuid as e, getBrowserLocale as n, getTimeZone as t, paramsParse as r, dotProp as i } from "analytics-utils";
import { get as a, remove as o, set as u, globalContext as c, KEY as s } from "@analytics/global-storage-utils";
import { isObject as l, PREFIX as f, isFunction as d, isBoolean as p, isString as m, isBrowser as g, isArray as h } from "@analytics/type-utils";
function v() {
  return v = Object.assign || function (e) {
    for (var n = 1; n < arguments.length; n++) {
      var t = arguments[n];
      for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
    }
    return e;
  }, v.apply(this, arguments);
}
var y = "function",
  b = "undefined",
  I = "@@redux/" + Math.random().toString(36),
  w = /* #__PURE__ */function () {
    return typeof Symbol === y && Symbol.observable || "@@observable";
  }(),
  E = " != " + y;
function P(e, n, t) {
  var r;
  if (typeof n === y && typeof t === b && (t = n, n = void 0), typeof t !== b) {
    if (typeof t !== y) throw new Error("enhancer" + E);
    return t(P)(e, n);
  }
  if (typeof e !== y) throw new Error("reducer" + E);
  var i = e,
    a = n,
    o = [],
    u = o,
    c = !1;
  function s() {
    u === o && (u = o.slice());
  }
  function f() {
    return a;
  }
  function d(e) {
    if (typeof e !== y) throw new Error("Listener" + E);
    var n = !0;
    return s(), u.push(e), function () {
      if (n) {
        n = !1, s();
        var t = u.indexOf(e);
        u.splice(t, 1);
      }
    };
  }
  function p(e) {
    if (!l(e)) throw new Error("Act != obj");
    if (typeof e.type === b) throw new Error("ActType " + b);
    if (c) throw new Error("Dispatch in reducer");
    try {
      c = !0, a = i(a, e);
    } finally {
      c = !1;
    }
    for (var n = o = u, t = 0; t < n.length; t++) (0, n[t])();
    return e;
  }
  return p({
    type: "@@redux/INIT"
  }), (r = {
    dispatch: p,
    subscribe: d,
    getState: f,
    replaceReducer: function (e) {
      if (typeof e !== y) throw new Error("next reducer" + E);
      i = e, p({
        type: "@@redux/INIT"
      });
    }
  })[w] = function () {
    var e,
      n = d;
    return (e = {
      subscribe: function (e) {
        if ("object" != typeof e) throw new TypeError("Observer != obj");
        function t() {
          e.next && e.next(f());
        }
        return t(), {
          unsubscribe: n(t)
        };
      }
    })[w] = function () {
      return this;
    }, e;
  }, r;
}
function S(e, n) {
  var t = n && n.type;
  return "action " + (t && t.toString() || "?") + "reducer " + e + " returns " + b;
}
function N() {
  var e = [].slice.call(arguments);
  return 0 === e.length ? function (e) {
    return e;
  } : 1 === e.length ? e[0] : e.reduce(function (e, n) {
    return function () {
      return e(n.apply(void 0, [].slice.call(arguments)));
    };
  });
}
function O() {
  var e = arguments;
  return function (n) {
    return function (t, r, i) {
      var a,
        o = n(t, r, i),
        u = o.dispatch,
        c = {
          getState: o.getState,
          dispatch: function (e) {
            return u(e);
          }
        };
      return a = [].slice.call(e).map(function (e) {
        return e(c);
      }), v({}, o, {
        dispatch: u = N.apply(void 0, a)(o.dispatch)
      });
    };
  };
}
var A = f + "anon_id",
  _ = f + "user_id",
  x = f + "user_traits",
  j = {
    __proto__: null,
    ANON_ID: A,
    USER_ID: _,
    USER_TRAITS: x
  },
  k = "userId",
  T = "anonymousId",
  z = ["bootstrap", "params", "campaign", "initializeStart", "initialize", "initializeEnd", "ready", "resetStart", "reset", "resetEnd", "pageStart", "page", "pageEnd", "pageAborted", "trackStart", "track", "trackEnd", "trackAborted", "identifyStart", "identify", "identifyEnd", "identifyAborted", "userIdChanged", "registerPlugins", "enablePlugin", "disablePlugin", "online", "offline", "setItemStart", "setItem", "setItemEnd", "setItemAborted", "removeItemStart", "removeItem", "removeItemEnd", "removeItemAborted"],
  M = ["name", "EVENTS", "config", "loaded"],
  q = z.reduce(function (e, n) {
    return e[n] = n, e;
  }, {
    registerPluginType: function (e) {
      return "registerPlugin:" + e;
    },
    pluginReadyType: function (e) {
      return "ready:" + e;
    }
  }),
  U = /^utm_/,
  V = /^an_prop_/,
  L = /^an_trait_/;
function C(e) {
  var n = e.storage.setItem;
  return function (t) {
    return function (r) {
      return function (i) {
        if (i.type === q.bootstrap) {
          var a = i.params,
            o = i.user,
            u = i.persistedUser,
            c = i.initialUser,
            s = u.userId === o.userId;
          u.anonymousId !== o.anonymousId && n(A, o.anonymousId), s || n(_, o.userId), c.traits && n(x, v({}, s && u.traits ? u.traits : {}, c.traits));
          var l = Object.keys(i.params);
          if (l.length) {
            var f = a.an_uid,
              d = a.an_event,
              p = l.reduce(function (e, n) {
                if (n.match(U) || n.match(/^(d|g)clid/)) {
                  var t = n.replace(U, "");
                  e.campaign["campaign" === t ? "name" : t] = a[n];
                }
                return n.match(V) && (e.props[n.replace(V, "")] = a[n]), n.match(L) && (e.traits[n.replace(L, "")] = a[n]), e;
              }, {
                campaign: {},
                props: {},
                traits: {}
              });
            t.dispatch(v({
              type: q.params,
              raw: a
            }, p, f ? {
              userId: f
            } : {})), f && setTimeout(function () {
              return e.identify(f, p.traits);
            }, 0), d && setTimeout(function () {
              return e.track(d, p.props);
            }, 0), Object.keys(p.campaign).length && t.dispatch({
              type: q.campaign,
              campaign: p.campaign
            });
          }
        }
        return r(i);
      };
    };
  };
}
function R(e) {
  return function (n, t) {
    if (void 0 === n && (n = {}), void 0 === t && (t = {}), t.type === q.setItemEnd) {
      if (t.key === A) return v({}, n, {
        anonymousId: t.value
      });
      if (t.key === _) return v({}, n, {
        userId: t.value
      });
    }
    switch (t.type) {
      case q.identify:
        return Object.assign({}, n, {
          userId: t.userId,
          traits: v({}, n.traits, t.traits)
        });
      case q.reset:
        return [_, A, x].forEach(function (n) {
          e.removeItem(n);
        }), Object.assign({}, n, {
          userId: null,
          anonymousId: null,
          traits: {}
        });
      default:
        return n;
    }
  };
}
function $(e) {
  return {
    userId: e.getItem(_),
    anonymousId: e.getItem(A),
    traits: e.getItem(x)
  };
}
var D = function (e) {
  return f + "TEMP" + f + e;
};
function B(n) {
  var t = n.storage,
    r = t.setItem,
    i = t.removeItem,
    a = t.getItem;
  return function (n) {
    return function (t) {
      return function (u) {
        var c = u.userId,
          s = u.traits,
          l = u.options;
        if (u.type === q.reset && ([_, x, A].forEach(function (e) {
          i(e);
        }), [k, T, "traits"].forEach(function (e) {
          o(D(e));
        })), u.type === q.identify) {
          a(A) || r(A, e());
          var f = a(_),
            d = a(x) || {};
          f && f !== c && n.dispatch({
            type: q.userIdChanged,
            old: {
              userId: f,
              traits: d
            },
            new: {
              userId: c,
              traits: s
            },
            options: l
          }), c && r(_, c), s && r(x, v({}, d, s));
        }
        return t(u);
      };
    };
  };
}
var X = {};
function J(e, n) {
  X[e] && d(X[e]) && (X[e](n), delete X[e]);
}
function W(e, n, t) {
  return new Promise(function (r, i) {
    return n() ? r(e) : t < 1 ? i(v({}, e, {
      queue: !0
    })) : new Promise(function (e) {
      return setTimeout(e, 10);
    }).then(function (a) {
      return W(e, n, t - 10).then(r, i);
    });
  });
}
function H(e) {
  return {
    abort: e
  };
}
function F(e, n, t) {
  var r = {},
    i = n(),
    a = e.getState(),
    o = a.plugins,
    u = a.queue,
    c = a.user;
  if (!a.context.offline && u && u.actions && u.actions.length) {
    var s = u.actions.reduce(function (e, n, t) {
      return o[n.plugin].loaded ? (e.process.push(n), e.processIndex.push(t)) : (e.requeue.push(n), e.requeueIndex.push(t)), e;
    }, {
      processIndex: [],
      process: [],
      requeue: [],
      requeueIndex: []
    });
    if (s.processIndex && s.processIndex.length) {
      s.processIndex.forEach(function (n) {
        var a = u.actions[n],
          s = a.plugin,
          f = a.payload.type,
          p = i[s][f];
        if (p && d(p)) {
          var m,
            g = function (e, n) {
              return void 0 === e && (e = {}), void 0 === n && (n = {}), [k, T].reduce(function (t, r) {
                return e.hasOwnProperty(r) && n[r] && n[r] !== e[r] && (t[r] = n[r]), t;
              }, e);
            }(a.payload, c),
            h = r[g.meta.rid];
          if (!h && (m = p({
            payload: g,
            config: o[s].config,
            instance: t,
            abort: H
          })) && l(m) && m.abort) return void (r[g.meta.rid] = !0);
          if (!h) {
            var y = f + ":" + s;
            e.dispatch(v({}, g, {
              type: y,
              _: {
                called: y,
                from: "queueDrain"
              }
            }));
          }
        }
      });
      var f = u.actions.filter(function (e, n) {
        return !~s.processIndex.indexOf(n);
      });
      u.actions = f;
    }
  }
}
var G = function (e) {
    var n = e.data,
      t = e.action,
      r = e.instance,
      i = e.state,
      a = e.allPlugins,
      o = e.allMatches,
      u = e.store,
      c = e.EVENTS;
    try {
      var s = i.plugins,
        f = i.context,
        p = t.type,
        m = p.match(K),
        g = n.exact.map(function (e) {
          return e.pluginName;
        });
      m && (g = o.during.map(function (e) {
        return e.pluginName;
      }));
      var h = function (e, n) {
          return function (t, r, i) {
            var a = r.config,
              o = r.name,
              u = o + "." + t.type;
            i && (u = i.event);
            var c = t.type.match(K) ? function (e, n, t, r, i) {
              return function (a, o) {
                var u = r ? r.name : e,
                  c = o && ae(o) ? o : t;
                if (r && (!(c = o && ae(o) ? o : [e]).includes(e) || 1 !== c.length)) throw new Error("Method " + n + " can only abort " + e + " plugin. " + JSON.stringify(c) + " input valid");
                return v({}, i, {
                  abort: {
                    reason: a,
                    plugins: c,
                    caller: n,
                    _: u
                  }
                });
              };
            }(o, u, n, i, t) : function (e, n) {
              return function () {
                throw new Error(e.type + " action not cancellable. Remove abort in " + n);
              };
            }(t, u);
            return {
              payload: ce(t),
              instance: e,
              config: a || {},
              abort: c
            };
          };
        }(r, g),
        y = n.exact.reduce(function (e, n) {
          var t = n.pluginName,
            r = n.methodName,
            i = !1;
          return r.match(/^initialize/) || r.match(/^reset/) || (i = !s[t].loaded), f.offline && r.match(/^(page|track|identify)/) && (i = !0), e["" + t] = i, e;
        }, {});
      return Promise.resolve(n.exact.reduce(function (e, i, o) {
        try {
          var u = i.pluginName;
          return Promise.resolve(e).then(function (e) {
            function i() {
              return Promise.resolve(e);
            }
            var o = function () {
              if (n.namespaced && n.namespaced[u]) return Promise.resolve(n.namespaced[u].reduce(function (e, n, t) {
                try {
                  return Promise.resolve(e).then(function (e) {
                    return n.method && d(n.method) ? (function (e, n) {
                      var t = ue(e);
                      if (t && t.name === n) {
                        var r = ue(t.method);
                        throw new Error([n + " plugin is calling method " + e, "Plugins cant call self", "Use " + t.method + " " + (r ? "or " + r.method : "") + " in " + n + " plugin insteadof " + e].join("\n"));
                      }
                    }(n.methodName, n.pluginName), Promise.resolve(n.method({
                      payload: e,
                      instance: r,
                      abort: (t = e, i = u, o = n.pluginName, function (e, n) {
                        return v({}, t, {
                          abort: {
                            reason: e,
                            plugins: n || [i],
                            caller: p,
                            from: o || i
                          }
                        });
                      }),
                      config: ee(n.pluginName, s, a),
                      plugins: s
                    })).then(function (n) {
                      var t = l(n) ? n : {};
                      return Promise.resolve(v({}, e, t));
                    })) : e;
                    var t, i, o;
                  });
                } catch (e) {
                  return Promise.reject(e);
                }
              }, Promise.resolve(t))).then(function (n) {
                e[u] = n;
              });
              e[u] = t;
            }();
            return o && o.then ? o.then(i) : i();
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }, Promise.resolve({}))).then(function (e) {
        return Promise.resolve(n.exact.reduce(function (t, i, o) {
          try {
            var c = n.exact.length === o + 1,
              f = i.pluginName,
              d = a[f];
            return Promise.resolve(t).then(function (n) {
              var t = e[f] ? e[f] : {};
              if (m && (t = n), re(t, f)) return Z({
                data: t,
                method: p,
                instance: r,
                pluginName: f,
                store: u
              }), Promise.resolve(n);
              if (re(n, f)) return c && Z({
                data: n,
                method: p,
                instance: r,
                store: u
              }), Promise.resolve(n);
              if (y.hasOwnProperty(f) && !0 === y[f]) return u.dispatch({
                type: "queue",
                plugin: f,
                payload: t,
                _: {
                  called: "queue",
                  from: "queueMechanism"
                }
              }), Promise.resolve(n);
              var i = h(e[f], a[f]);
              return Promise.resolve(d[p]({
                abort: i.abort,
                payload: t,
                instance: r,
                config: ee(f, s, a),
                plugins: s
              })).then(function (i) {
                var a = l(i) ? i : {},
                  o = v({}, n, a),
                  c = e[f];
                if (re(c, f)) Z({
                  data: c,
                  method: p,
                  instance: r,
                  pluginName: f,
                  store: u
                });else {
                  var s = p + ":" + f;
                  (s.match(/:/g) || []).length < 2 && !p.match(Q) && !p.match(Y) && r.dispatch(v({}, m ? o : t, {
                    type: s,
                    _: {
                      called: s,
                      from: "submethod"
                    }
                  }));
                }
                return Promise.resolve(o);
              });
            });
          } catch (e) {
            return Promise.reject(e);
          }
        }, Promise.resolve(t))).then(function (e) {
          if (!(p.match(K) || p.match(/^registerPlugin/) || p.match(Y) || p.match(Q) || p.match(/^params/) || p.match(/^userIdChanged/))) {
            if (c.plugins.includes(p), e._ && e._.originalAction === p) return e;
            var t = v({}, e, {
              _: {
                originalAction: e.type,
                called: e.type,
                from: "engineEnd"
              }
            });
            ie(e, n.exact.length) && !p.match(/End$/) && (t = v({}, t, {
              type: e.type + "Aborted"
            })), u.dispatch(t);
          }
          return e;
        });
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  K = /Start$/,
  Q = /^bootstrap/,
  Y = /^ready/;
function Z(e) {
  var n = e.pluginName,
    t = e.method + "Aborted" + (n ? ":" + n : "");
  e.store.dispatch(v({}, e.data, {
    type: t,
    _: {
      called: t,
      from: "abort"
    }
  }));
}
function ee(e, n, t) {
  var r = n[e] || t[e];
  return r && r.config ? r.config : {};
}
function ne(e, n) {
  return n.reduce(function (n, t) {
    return t[e] ? n.concat({
      methodName: e,
      pluginName: t.name,
      method: t[e]
    }) : n;
  }, []);
}
function te(e, n) {
  var t = e.replace(K, ""),
    r = n ? ":" + n : "";
  return ["" + e + r, "" + t + r, t + "End" + r];
}
function re(e, n) {
  var t = e.abort;
  return !!t && (!0 === t || oe(t, n) || t && oe(t.plugins, n));
}
function ie(e, n) {
  var t = e.abort;
  if (!t) return !1;
  if (!0 === t || m(t)) return !0;
  var r = t.plugins;
  return ae(t) && t.length === n || ae(r) && r.length === n;
}
function ae(e) {
  return Array.isArray(e);
}
function oe(e, n) {
  return !(!e || !ae(e)) && e.includes(n);
}
function ue(e) {
  var n = e.match(/(.*):(.*)/);
  return !!n && {
    method: n[1],
    name: n[2]
  };
}
function ce(e) {
  return Object.keys(e).reduce(function (n, t) {
    return "type" === t || (n[t] = l(e[t]) ? Object.assign({}, e[t]) : e[t]), n;
  }, {});
}
function se(e, n, t) {
  var r = {};
  return function (i) {
    return function (a) {
      return function (o) {
        try {
          var u,
            c = function (e) {
              return u ? e : a(f);
            },
            s = o.type,
            l = o.plugins,
            f = o;
          if (o.abort) return Promise.resolve(a(o));
          if (s === q.enablePlugin && i.dispatch({
            type: q.initializeStart,
            plugins: l,
            disabled: [],
            fromEnable: !0,
            meta: o.meta
          }), s === q.disablePlugin && setTimeout(function () {
            return J(o.meta.rid, {
              payload: o
            });
          }, 0), s === q.initializeEnd) {
            var m = n(),
              g = Object.keys(m),
              h = g.filter(function (e) {
                return l.includes(e);
              }).map(function (e) {
                return m[e];
              }),
              y = [],
              b = [],
              I = o.disabled,
              w = h.map(function (e) {
                var n = e.loaded,
                  t = e.name,
                  a = e.config;
                return W(e, function () {
                  return n({
                    config: a
                  });
                }, 1e4).then(function (n) {
                  return r[t] || (i.dispatch({
                    type: q.pluginReadyType(t),
                    name: t,
                    events: Object.keys(e).filter(function (e) {
                      return !M.includes(e);
                    })
                  }), r[t] = !0), y = y.concat(t), e;
                }).catch(function (e) {
                  if (e instanceof Error) throw new Error(e);
                  return b = b.concat(e.name), e;
                });
              });
            Promise.all(w).then(function (e) {
              var n = {
                plugins: y,
                failed: b,
                disabled: I
              };
              setTimeout(function () {
                g.length === w.length + I.length && i.dispatch(v({}, {
                  type: q.ready
                }, n));
              }, 0);
            });
          }
          var E = function () {
            if (s !== q.bootstrap) return /^ready:([^:]*)$/.test(s) && setTimeout(function () {
              return F(i, n, e);
            }, 0), Promise.resolve(function (e, n, t, r, i) {
              try {
                var a = d(n) ? n() : n,
                  o = e.type,
                  u = o.replace(K, "");
                if (e._ && e._.called) return Promise.resolve(e);
                var c = t.getState(),
                  s = (m = a, void 0 === (g = c.plugins) && (g = {}), void 0 === (h = e.options) && (h = {}), Object.keys(m).filter(function (e) {
                    var n = h.plugins || {};
                    return p(n[e]) ? n[e] : !1 !== n.all && (!g[e] || !1 !== g[e].enabled);
                  }).map(function (e) {
                    return m[e];
                  }));
                o === q.initializeStart && e.fromEnable && (s = Object.keys(c.plugins).filter(function (n) {
                  var t = c.plugins[n];
                  return e.plugins.includes(n) && !t.initialized;
                }).map(function (e) {
                  return a[e];
                }));
                var l = s.map(function (e) {
                    return e.name;
                  }),
                  f = function (e, n, t) {
                    var r = te(e).map(function (e) {
                      return ne(e, n);
                    });
                    return n.reduce(function (t, r) {
                      var i = r.name,
                        a = te(e, i).map(function (e) {
                          return ne(e, n);
                        }),
                        o = a[0],
                        u = a[1],
                        c = a[2];
                      return o.length && (t.beforeNS[i] = o), u.length && (t.duringNS[i] = u), c.length && (t.afterNS[i] = c), t;
                    }, {
                      before: r[0],
                      beforeNS: {},
                      during: r[1],
                      duringNS: {},
                      after: r[2],
                      afterNS: {}
                    });
                  }(o, s);
                return Promise.resolve(G({
                  action: e,
                  data: {
                    exact: f.before,
                    namespaced: f.beforeNS
                  },
                  state: c,
                  allPlugins: a,
                  allMatches: f,
                  instance: t,
                  store: r,
                  EVENTS: i
                })).then(function (e) {
                  function n() {
                    var n = function () {
                      if (o.match(K)) return Promise.resolve(G({
                        action: v({}, s, {
                          type: u + "End"
                        }),
                        data: {
                          exact: f.after,
                          namespaced: f.afterNS
                        },
                        state: c,
                        allPlugins: a,
                        allMatches: f,
                        instance: t,
                        store: r,
                        EVENTS: i
                      })).then(function (e) {
                        e.meta && e.meta.hasCallback && J(e.meta.rid, {
                          payload: e
                        });
                      });
                    }();
                    return n && n.then ? n.then(function () {
                      return e;
                    }) : e;
                  }
                  if (ie(e, l.length)) return e;
                  var s,
                    d = function () {
                      if (o !== u) return Promise.resolve(G({
                        action: v({}, e, {
                          type: u
                        }),
                        data: {
                          exact: f.during,
                          namespaced: f.duringNS
                        },
                        state: c,
                        allPlugins: a,
                        allMatches: f,
                        instance: t,
                        store: r,
                        EVENTS: i
                      })).then(function (e) {
                        s = e;
                      });
                      s = e;
                    }();
                  return d && d.then ? d.then(n) : n();
                });
              } catch (e) {
                return Promise.reject(e);
              }
              var m, g, h;
            }(o, n, e, i, t)).then(function (e) {
              return u = 1, a(e);
            });
          }();
          return Promise.resolve(E && E.then ? E.then(c) : c(E));
        } catch (e) {
          return Promise.reject(e);
        }
      };
    };
  };
}
function le(e) {
  return function (n) {
    return function (n) {
      return function (t) {
        var r = t.type,
          i = t.key,
          a = t.value,
          o = t.options;
        if (r === q.setItem || r === q.removeItem) {
          if (t.abort) return n(t);
          r === q.setItem ? e.setItem(i, a, o) : e.removeItem(i, o);
        }
        return n(t);
      };
    };
  };
}
var fe = function () {
  var e = this;
  this.before = [], this.after = [], this.addMiddleware = function (n, t) {
    e[t] = e[t].concat(n);
  }, this.removeMiddleware = function (n, t) {
    var r = e[t].findIndex(function (e) {
      return e === n;
    });
    -1 !== r && (e[t] = [].concat(e[t].slice(0, r), e[t].slice(r + 1)));
  }, this.dynamicMiddlewares = function (n) {
    return function (t) {
      return function (r) {
        return function (i) {
          var a = {
              getState: t.getState,
              dispatch: function (e) {
                return t.dispatch(e);
              }
            },
            o = e[n].map(function (e) {
              return e(a);
            });
          return N.apply(void 0, o)(r)(i);
        };
      };
    };
  };
};
function de(e) {
  return function (n, t) {
    void 0 === n && (n = {});
    var r = {};
    if ("initialize:aborted" === t.type) return n;
    if (/^registerPlugin:([^:]*)$/.test(t.type)) {
      var i = pe(t.type, "registerPlugin"),
        a = e()[i];
      if (!a || !i) return n;
      var o = t.enabled,
        u = a.config;
      return r[i] = {
        enabled: o,
        initialized: !!o && Boolean(!a.initialize),
        loaded: !!o && Boolean(a.loaded({
          config: u
        })),
        config: u
      }, v({}, n, r);
    }
    if (/^initialize:([^:]*)$/.test(t.type)) {
      var c = pe(t.type, q.initialize),
        s = e()[c];
      return s && c ? (r[c] = v({}, n[c], {
        initialized: !0,
        loaded: Boolean(s.loaded({
          config: s.config
        }))
      }), v({}, n, r)) : n;
    }
    if (/^ready:([^:]*)$/.test(t.type)) return r[t.name] = v({}, n[t.name], {
      loaded: !0
    }), v({}, n, r);
    switch (t.type) {
      case q.disablePlugin:
        return v({}, n, me(t.plugins, !1, n));
      case q.enablePlugin:
        return v({}, n, me(t.plugins, !0, n));
      default:
        return n;
    }
  };
}
function pe(e, n) {
  return e.substring(n.length + 1, e.length);
}
function me(e, n, t) {
  return e.reduce(function (e, r) {
    return e[r] = v({}, t[r], {
      enabled: n
    }), e;
  }, t);
}
function ge(e) {
  try {
    return JSON.parse(JSON.stringify(e));
  } catch (e) {}
  return e;
}
var he = {
  last: {},
  history: []
};
function ve(e, n) {
  void 0 === e && (e = he);
  var t = n.options,
    r = n.meta;
  if (n.type === q.track) {
    var i = ge(v({
      event: n.event,
      properties: n.properties
    }, Object.keys(t).length && {
      options: t
    }, {
      meta: r
    }));
    return v({}, e, {
      last: i,
      history: e.history.concat(i)
    });
  }
  return e;
}
var ye = {
  actions: []
};
function be(e, n) {
  void 0 === e && (e = ye);
  var t = n.payload;
  switch (n.type) {
    case "queue":
      var r;
      return r = t && t.type && t.type === q.identify ? [n].concat(e.actions) : e.actions.concat(n), v({}, e, {
        actions: r
      });
    case "dequeue":
      return [];
    default:
      return e;
  }
}
var Ie = /#.*$/;
function we(e) {
  var n = /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/g.exec(e);
  return "/" + (n && n[3] ? n[3].split("?")[0].replace(Ie, "") : "");
}
var Ee,
  Pe,
  Se,
  Ne,
  Oe = function (e) {
    if (void 0 === e && (e = {}), !g) return e;
    var n = document,
      t = n.title,
      r = n.referrer,
      i = window,
      a = i.location,
      o = i.innerWidth,
      u = i.innerHeight,
      c = a.hash,
      s = a.search,
      l = function (e) {
        var n = function () {
          if (g) for (var e, n = document.getElementsByTagName("link"), t = 0; e = n[t]; t++) if ("canonical" === e.getAttribute("rel")) return e.getAttribute("href");
        }();
        return n ? n.match(/\?/) ? n : n + e : window.location.href.replace(Ie, "");
      }(s),
      f = {
        title: t,
        url: l,
        path: we(l),
        hash: c,
        search: s,
        width: o,
        height: u
      };
    return r && "" !== r && (f.referrer = r), v({}, f, e);
  },
  Ae = {
    last: {},
    history: []
  };
function _e(e, n) {
  void 0 === e && (e = Ae);
  var t = n.options;
  if (n.type === q.page) {
    var r = ge(v({
      properties: n.properties,
      meta: n.meta
    }, Object.keys(t).length && {
      options: t
    }));
    return v({}, e, {
      last: r,
      history: e.history.concat(r)
    });
  }
  return e;
}
Ee = function () {
  if (!g) return !1;
  var e = navigator.appVersion;
  return ~e.indexOf("Win") ? "Windows" : ~e.indexOf("Mac") ? "MacOS" : ~e.indexOf("X11") ? "UNIX" : ~e.indexOf("Linux") ? "Linux" : "Unknown OS";
}(), Pe = g ? document.referrer : null, Se = n(), Ne = t();
var xe = {
  initialized: !1,
  sessionId: e(),
  app: null,
  version: null,
  debug: !1,
  offline: !!g && !navigator.onLine,
  os: {
    name: Ee
  },
  userAgent: g ? navigator.userAgent : "node",
  library: {
    name: "analytics",
    version: "0.12.16"
  },
  timezone: Ne,
  locale: Se,
  campaign: {},
  referrer: Pe
};
function je(e, n) {
  void 0 === e && (e = xe);
  var t = e.initialized,
    r = n.campaign;
  switch (n.type) {
    case q.campaign:
      return v({}, e, {
        campaign: r
      });
    case q.offline:
      return v({}, e, {
        offline: !0
      });
    case q.online:
      return v({}, e, {
        offline: !1
      });
    default:
      return t ? e : v({}, xe, e, {
        initialized: !0
      });
  }
}
var ke = ["plugins", "reducers", "storage"];
function Te(e, n, t) {
  if (g) {
    var r = window[(t ? "add" : "remove") + "EventListener"];
    e.split(" ").forEach(function (e) {
      r(e, n);
    });
  }
}
function ze(e) {
  var n = Te.bind(null, "online offline", function (n) {
    return Promise.resolve(!navigator.onLine).then(e);
  });
  return n(!0), function (e) {
    return n(!1);
  };
}
function Me() {
  return u("analytics", []), function (e) {
    return function (n, t, r) {
      var i = e(n, t, r),
        a = i.dispatch;
      return Object.assign(i, {
        dispatch: function (e) {
          return c[s].analytics.push(e.action || e), a(e);
        }
      });
    };
  };
}
function qe(e) {
  return function () {
    return N(N.apply(null, arguments), Me());
  };
}
function Ue(e) {
  return e ? h(e) ? e : [e] : [];
}
function Ve(n, t, r) {
  void 0 === n && (n = {});
  var i,
    a,
    o = e();
  return t && (X[o] = (i = t, a = function (e) {
    for (var n, t = e || Array.prototype.slice.call(arguments), r = 0; r < t.length; r++) if (d(t[r])) {
      n = t[r];
      break;
    }
    return n;
  }(r), function (e) {
    a && a(e), i(e);
  })), v({}, n, {
    rid: o,
    ts: new Date().getTime()
  }, t ? {
    hasCallback: !0
  } : {});
}
function Le(n) {
  void 0 === n && (n = {});
  var t = n.reducers || {},
    c = n.initialUser || {},
    s = (n.plugins || []).reduce(function (e, n) {
      if (d(n)) return e.middlewares = e.middlewares.concat(n), e;
      if (n.NAMESPACE && (n.name = n.NAMESPACE), !n.name) throw new Error("https://lytics.dev/errors/1");
      n.config || (n.config = {});
      var t = n.EVENTS ? Object.keys(n.EVENTS).map(function (e) {
        return n.EVENTS[e];
      }) : [];
      e.pluginEnabled[n.name] = !(!1 === n.enabled || !1 === n.config.enabled), delete n.enabled, n.methods && (e.methods[n.name] = Object.keys(n.methods).reduce(function (e, t) {
        var r;
        return e[t] = (r = n.methods[t], function () {
          for (var e = Array.prototype.slice.call(arguments), n = new Array(r.length), t = 0; t < e.length; t++) n[t] = e[t];
          return n[n.length] = Q, r.apply({
            instance: Q
          }, n);
        }), e;
      }, {}), delete n.methods);
      var r = Object.keys(n).concat(t),
        i = new Set(e.events.concat(r));
      if (e.events = Array.from(i), e.pluginsArray = e.pluginsArray.concat(n), e.plugins[n.name]) throw new Error(n.name + "AlreadyLoaded");
      return e.plugins[n.name] = n, e.plugins[n.name].loaded || (e.plugins[n.name].loaded = function () {
        return !0;
      }), e;
    }, {
      plugins: {},
      pluginEnabled: {},
      methods: {},
      pluginsArray: [],
      middlewares: [],
      events: []
    }),
    f = n.storage ? n.storage : {
      getItem: a,
      setItem: u,
      removeItem: o
    },
    p = function (e) {
      return function (n, t, r) {
        return t.getState("user")[n] || (r && l(r) && r[n] ? r[n] : $(e)[n] || a(D(n)) || null);
      };
    }(f),
    h = s.plugins,
    w = s.events.filter(function (e) {
      return !M.includes(e);
    }).sort(),
    E = new Set(w.concat(z).filter(function (e) {
      return !M.includes(e);
    })),
    _ = Array.from(E).sort(),
    x = function () {
      return h;
    },
    j = new fe(),
    U = j.addMiddleware,
    V = j.removeMiddleware,
    L = j.dynamicMiddlewares,
    X = function () {
      throw new Error("Abort disabled inListener");
    },
    J = r(),
    W = $(f),
    H = v({}, W, c, J.an_uid ? {
      userId: J.an_uid
    } : {}, J.an_aid ? {
      anonymousId: J.an_aid
    } : {});
  H.anonymousId || (H.anonymousId = e());
  var G = v({
      enable: function (e, n) {
        return new Promise(function (t) {
          ue.dispatch({
            type: q.enablePlugin,
            plugins: Ue(e),
            _: {
              originalAction: q.enablePlugin
            }
          }, t, [n]);
        });
      },
      disable: function (e, n) {
        return new Promise(function (t) {
          ue.dispatch({
            type: q.disablePlugin,
            plugins: Ue(e),
            _: {
              originalAction: q.disablePlugin
            }
          }, t, [n]);
        });
      }
    }, s.methods),
    K = !1,
    Q = {
      identify: function (e, n, t, r) {
        try {
          var i = m(e) ? e : null,
            a = l(e) ? e : n,
            o = t || {},
            c = Q.user();
          u(D(k), i);
          var s = i || a.userId || p(k, Q, a);
          return Promise.resolve(new Promise(function (e) {
            ue.dispatch(v({
              type: q.identifyStart,
              userId: s,
              traits: a || {},
              options: o,
              anonymousId: c.anonymousId
            }, c.id && c.id !== i && {
              previousId: c.id
            }), e, [n, t, r]);
          }));
        } catch (e) {
          return Promise.reject(e);
        }
      },
      track: function (e, n, t, r) {
        try {
          var i = l(e) ? e.event : e;
          if (!i || !m(i)) throw new Error("EventMissing");
          var a = l(e) ? e : n || {},
            o = l(t) ? t : {};
          return Promise.resolve(new Promise(function (e) {
            ue.dispatch({
              type: q.trackStart,
              event: i,
              properties: a,
              options: o,
              userId: p(k, Q, n),
              anonymousId: p(T, Q, n)
            }, e, [n, t, r]);
          }));
        } catch (e) {
          return Promise.reject(e);
        }
      },
      page: function (e, n, t) {
        try {
          var r = l(e) ? e : {},
            i = l(n) ? n : {};
          return Promise.resolve(new Promise(function (a) {
            ue.dispatch({
              type: q.pageStart,
              properties: Oe(r),
              options: i,
              userId: p(k, Q, r),
              anonymousId: p(T, Q, r)
            }, a, [e, n, t]);
          }));
        } catch (e) {
          return Promise.reject(e);
        }
      },
      user: function (e) {
        if (e === k || "id" === e) return p(k, Q);
        if (e === T || "anonId" === e) return p(T, Q);
        var n = Q.getState("user");
        return e ? i(n, e) : n;
      },
      reset: function (e) {
        return new Promise(function (n) {
          ue.dispatch({
            type: q.resetStart
          }, n, e);
        });
      },
      ready: function (e) {
        return K && e({
          plugins: G,
          instance: Q
        }), Q.on(q.ready, function (n) {
          e(n), K = !0;
        });
      },
      on: function (e, n) {
        if (!e || !d(n)) return !1;
        if (e === q.bootstrap) throw new Error(".on disabled for " + e);
        var t = /Start$|Start:/;
        if ("*" === e) {
          var r = function (e) {
              return function (e) {
                return function (r) {
                  return r.type.match(t) && n({
                    payload: r,
                    instance: Q,
                    plugins: h
                  }), e(r);
                };
              };
            },
            i = function (e) {
              return function (e) {
                return function (r) {
                  return r.type.match(t) || n({
                    payload: r,
                    instance: Q,
                    plugins: h
                  }), e(r);
                };
              };
            };
          return U(r, Ce), U(i, Re), function () {
            V(r, Ce), V(i, Re);
          };
        }
        var a = e.match(t) ? Ce : Re,
          o = function (t) {
            return function (t) {
              return function (r) {
                return r.type === e && n({
                  payload: r,
                  instance: Q,
                  plugins: h,
                  abort: X
                }), t(r);
              };
            };
          };
        return U(o, a), function () {
          return V(o, a);
        };
      },
      once: function (e, n) {
        if (!e || !d(n)) return !1;
        if (e === q.bootstrap) throw new Error(".once disabled for " + e);
        var t = Q.on(e, function (e) {
          n({
            payload: e.payload,
            instance: Q,
            plugins: h,
            abort: X
          }), t();
        });
        return t;
      },
      getState: function (e) {
        var n = ue.getState();
        return e ? i(n, e) : Object.assign({}, n);
      },
      dispatch: function (e) {
        var n = m(e) ? {
          type: e
        } : e;
        if (z.includes(n.type)) throw new Error("reserved action " + n.type);
        var t = v({}, n, {
          _: v({
            originalAction: n.type
          }, e._ || {})
        });
        ue.dispatch(t);
      },
      enablePlugin: G.enable,
      disablePlugin: G.disable,
      plugins: G,
      storage: {
        getItem: f.getItem,
        setItem: function (e, n, t) {
          ue.dispatch({
            type: q.setItemStart,
            key: e,
            value: n,
            options: t
          });
        },
        removeItem: function (e, n) {
          ue.dispatch({
            type: q.removeItemStart,
            key: e,
            options: n
          });
        }
      },
      setAnonymousId: function (e, n) {
        Q.storage.setItem(A, e, n);
      },
      events: {
        core: z,
        plugins: w
      }
    },
    Y = s.middlewares.concat([function (e) {
      return function (e) {
        return function (n) {
          return n.meta || (n.meta = Ve()), e(n);
        };
      };
    }, L(Ce), se(Q, x, {
      all: _,
      plugins: w
    }), le(f), C(Q), B(Q), L(Re)]),
    Z = {
      context: je,
      user: R(f),
      page: _e,
      track: ve,
      plugins: de(x),
      queue: be
    },
    ee = N,
    ne = N;
  if (g && n.debug) {
    var te = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    te && (ee = te({
      trace: !0,
      traceLimit: 25
    })), ne = function () {
      return 0 === arguments.length ? Me() : l(typeof arguments[0]) ? qe() : qe().apply(null, arguments);
    };
  }
  var re,
    ie = function (e) {
      return Object.keys(e).reduce(function (n, t) {
        return ke.includes(t) || (n[t] = e[t]), n;
      }, {});
    }(n),
    ae = s.pluginsArray.reduce(function (e, n) {
      var t = n.name,
        r = n.config,
        i = n.loaded,
        a = s.pluginEnabled[t];
      return e[t] = {
        enabled: a,
        initialized: !!a && Boolean(!n.initialize),
        loaded: Boolean(i({
          config: r
        })),
        config: r
      }, e;
    }, {}),
    oe = {
      context: ie,
      user: H,
      plugins: ae
    },
    ue = P(function (e) {
      for (var n = Object.keys(e), t = {}, r = 0; r < n.length; r++) {
        var i = n[r];
        typeof e[i] === y && (t[i] = e[i]);
      }
      var a,
        o = Object.keys(t);
      try {
        !function (e) {
          Object.keys(e).forEach(function (n) {
            var t = e[n];
            if (typeof t(void 0, {
              type: "@@redux/INIT"
            }) === b || typeof t(void 0, {
              type: I
            }) === b) throw new Error("reducer " + n + " " + b);
          });
        }(t);
      } catch (e) {
        a = e;
      }
      return function (e, n) {
        if (void 0 === e && (e = {}), a) throw a;
        for (var r = !1, i = {}, u = 0; u < o.length; u++) {
          var c = o[u],
            s = e[c],
            l = (0, t[c])(s, n);
          if (typeof l === b) {
            var f = S(c, n);
            throw new Error(f);
          }
          i[c] = l, r = r || l !== s;
        }
        return r ? i : e;
      };
    }(v({}, Z, t)), oe, ne(ee(O.apply(void 0, Y))));
  ue.dispatch = (re = ue.dispatch, function (e, n, t) {
    var r = v({}, e, {
      meta: Ve(e.meta, n, Ue(t))
    });
    return re.apply(null, [r]);
  });
  var ce = Object.keys(h);
  ue.dispatch({
    type: q.bootstrap,
    plugins: ce,
    config: ie,
    params: J,
    user: H,
    initialUser: c,
    persistedUser: W
  });
  var pe = ce.filter(function (e) {
      return s.pluginEnabled[e];
    }),
    me = ce.filter(function (e) {
      return !s.pluginEnabled[e];
    });
  return ue.dispatch({
    type: q.registerPlugins,
    plugins: ce,
    enabled: s.pluginEnabled
  }), s.pluginsArray.map(function (e, n) {
    var t = e.bootstrap,
      r = e.config,
      i = e.name;
    t && d(t) && t({
      instance: Q,
      config: r,
      payload: e
    }), ue.dispatch({
      type: q.registerPluginType(i),
      name: i,
      enabled: s.pluginEnabled[i],
      plugin: e
    }), s.pluginsArray.length === n + 1 && ue.dispatch({
      type: q.initializeStart,
      plugins: pe,
      disabled: me
    });
  }), ze(function (e) {
    ue.dispatch({
      type: e ? q.offline : q.online
    });
  }), function (e, n, t) {
    setInterval(function () {
      return F(e, n, t);
    }, 3e3);
  }(ue, x, Q), Q;
}
var Ce = "before",
  Re = "after";
export { Le as Analytics, j as CONSTANTS, q as EVENTS, Le as default, Le as init };
