/* eslint-disable @typescript-eslint/unbound-method */
import client from './browser-client-factory';
export { createInstance } from './browser-client-factory';
export var add = client.add,
  extendSession = client.extendSession,
  flush = client.flush,
  getDeviceId = client.getDeviceId,
  getSessionId = client.getSessionId,
  getUserId = client.getUserId,
  groupIdentify = client.groupIdentify,
  identify = client.identify,
  init = client.init,
  logEvent = client.logEvent,
  remove = client.remove,
  reset = client.reset,
  revenue = client.revenue,
  setDeviceId = client.setDeviceId,
  setGroup = client.setGroup,
  setOptOut = client.setOptOut,
  setSessionId = client.setSessionId,
  setTransport = client.setTransport,
  setUserId = client.setUserId,
  track = client.track;
export { runQueuedFunctions } from './utils/snippet-helper';
export { Revenue, Identify } from '@amplitude/analytics-core';
import { IdentifyOperation as IdentifyOperationType, SpecialEventType as SpecialEventTypeType, RevenueProperty as RevenuePropertyType, LogLevel as LogLevelType, ServerZone as ServerZoneEnum, OfflineDisabled as OfflineDisabledType, DEFAULT_CSS_SELECTOR_ALLOWLIST as DEFAULT_CSS_SELECTOR_ALLOWLIST_TYPE, DEFAULT_DATA_ATTRIBUTE_PREFIX as DEFAULT_DATA_ATTRIBUTE_PREFIX_TYPE, DEFAULT_ACTION_CLICK_ALLOWLIST as DEFAULT_ACTION_CLICK_ALLOWLIST_TYPE } from '@amplitude/analytics-core';
// Export the following types to maintain backward compatibility with `analytics-types`.
// In the next major version, only export customer-facing types to reduce the public API surface.
// eslint-disable-next-line @typescript-eslint/no-namespace
export var Types;
(function (Types) {
  Types.IdentifyOperation = IdentifyOperationType;
  Types.SpecialEventType = SpecialEventTypeType;
  Types.RevenueProperty = RevenuePropertyType;
  Types.LogLevel = LogLevelType;
  Types.ServerZone = ServerZoneEnum;
  Types.OfflineDisabled = OfflineDisabledType;
  Types.DEFAULT_CSS_SELECTOR_ALLOWLIST = DEFAULT_CSS_SELECTOR_ALLOWLIST_TYPE;
  Types.DEFAULT_DATA_ATTRIBUTE_PREFIX = DEFAULT_DATA_ATTRIBUTE_PREFIX_TYPE;
  Types.DEFAULT_ACTION_CLICK_ALLOWLIST = DEFAULT_ACTION_CLICK_ALLOWLIST_TYPE;
})(Types || (Types = {}));
