import { __assign, __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
/* eslint-disable no-restricted-globals */
import { DEFAULT_CSS_SELECTOR_ALLOWLIST, DEFAULT_DATA_ATTRIBUTE_PREFIX, DEFAULT_ACTION_CLICK_ALLOWLIST } from '@amplitude/analytics-core';
import * as constants from './constants';
import { fromEvent, map, Observable, share } from 'rxjs';
import { getText, getAttributesWithPrefix, removeEmptyProperties, getNearestLabel, createShouldTrackEvent, getClosestElement } from './helpers';
import { WindowMessenger } from './libs/messenger';
import { getHierarchy } from './hierarchy';
import { trackClicks } from './autocapture/track-click';
import { trackChange } from './autocapture/track-change';
import { trackActionClick } from './autocapture/track-action-click';
export var ObservablesEnum;
(function (ObservablesEnum) {
  ObservablesEnum["ClickObservable"] = "clickObservable";
  ObservablesEnum["ChangeObservable"] = "changeObservable";
  // ErrorObservable = 'errorObservable',
  ObservablesEnum["NavigateObservable"] = "navigateObservable";
  ObservablesEnum["MutationObservable"] = "mutationObservable";
})(ObservablesEnum || (ObservablesEnum = {}));
// Type predicate
export function isElementBasedEvent(event) {
  return event.type === 'click' || event.type === 'change';
}
export var autocapturePlugin = function (options) {
  var _a, _b, _c;
  if (options === void 0) {
    options = {};
  }
  var _d = options.dataAttributePrefix,
    dataAttributePrefix = _d === void 0 ? DEFAULT_DATA_ATTRIBUTE_PREFIX : _d,
    _e = options.visualTaggingOptions,
    visualTaggingOptions = _e === void 0 ? {
      enabled: true,
      messenger: new WindowMessenger()
    } : _e;
  options.cssSelectorAllowlist = (_a = options.cssSelectorAllowlist) !== null && _a !== void 0 ? _a : DEFAULT_CSS_SELECTOR_ALLOWLIST;
  options.actionClickAllowlist = (_b = options.actionClickAllowlist) !== null && _b !== void 0 ? _b : DEFAULT_ACTION_CLICK_ALLOWLIST;
  options.debounceTime = (_c = options.debounceTime) !== null && _c !== void 0 ? _c : 0; // TODO: update this when rage clicks are added to 1000ms
  var name = constants.PLUGIN_NAME;
  var type = 'enrichment';
  var subscriptions = [];
  // Create observables on events on the window
  var createObservables = function () {
    var _a;
    // Create Observables from direct user events
    var clickObservable = fromEvent(document, 'click', {
      capture: true
    }).pipe(map(function (click) {
      return addAdditionalEventProperties(click, 'click');
    }), share());
    var changeObservable = fromEvent(document, 'change', {
      capture: true
    }).pipe(map(function (change) {
      return addAdditionalEventProperties(change, 'change');
    }), share());
    // Create Observable from unhandled errors
    // const errorObservable = fromEvent<ErrorEvent>(window, 'error').pipe(
    //   map((error) => addAdditionalEventProperties(error, 'error')),
    // );
    // Create observable for URL changes
    var navigateObservable;
    /* istanbul ignore next */
    if (window.navigation) {
      navigateObservable = fromEvent(window.navigation, 'navigate').pipe(map(function (navigate) {
        return addAdditionalEventProperties(navigate, 'navigate');
      }), share());
    }
    // Track DOM Mutations
    var mutationObservable = new Observable(function (observer) {
      var mutationObserver = new MutationObserver(function (mutations) {
        observer.next(mutations);
      });
      mutationObserver.observe(document.body, {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true
      });
      return function () {
        return mutationObserver.disconnect();
      };
    }).pipe(map(function (mutation) {
      return addAdditionalEventProperties(mutation, 'mutation');
    }), share());
    return _a = {}, _a[ObservablesEnum.ClickObservable] = clickObservable, _a[ObservablesEnum.ChangeObservable] = changeObservable,
    // [ObservablesEnum.ErrorObservable]: errorObservable,
    _a[ObservablesEnum.NavigateObservable] = navigateObservable, _a[ObservablesEnum.MutationObservable] = mutationObservable, _a;
  };
  // Returns the Amplitude event properties for the given element.
  var getEventProperties = function (actionType, element) {
    var _a;
    var _b, _c;
    /* istanbul ignore next */
    var tag = (_c = (_b = element === null || element === void 0 ? void 0 : element.tagName) === null || _b === void 0 ? void 0 : _b.toLowerCase) === null || _c === void 0 ? void 0 : _c.call(_b);
    /* istanbul ignore next */
    var rect = typeof element.getBoundingClientRect === 'function' ? element.getBoundingClientRect() : {
      left: null,
      top: null
    };
    var ariaLabel = element.getAttribute('aria-label');
    var attributes = getAttributesWithPrefix(element, dataAttributePrefix);
    var nearestLabel = getNearestLabel(element);
    /* istanbul ignore next */
    var properties = (_a = {}, _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_ID] = element.getAttribute('id') || '', _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_CLASS] = element.getAttribute('class'), _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_HIERARCHY] = getHierarchy(element), _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_TAG] = tag, _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_TEXT] = getText(element), _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_POSITION_LEFT] = rect.left == null ? null : Math.round(rect.left), _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_POSITION_TOP] = rect.top == null ? null : Math.round(rect.top), _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_ARIA_LABEL] = ariaLabel, _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_ATTRIBUTES] = attributes, _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_PARENT_LABEL] = nearestLabel, _a[constants.AMPLITUDE_EVENT_PROP_PAGE_URL] = window.location.href.split('?')[0], _a[constants.AMPLITUDE_EVENT_PROP_PAGE_TITLE] = typeof document !== 'undefined' && document.title || '', _a[constants.AMPLITUDE_EVENT_PROP_VIEWPORT_HEIGHT] = window.innerHeight, _a[constants.AMPLITUDE_EVENT_PROP_VIEWPORT_WIDTH] = window.innerWidth, _a);
    if (tag === 'a' && actionType === 'click' && element instanceof HTMLAnchorElement) {
      properties[constants.AMPLITUDE_EVENT_PROP_ELEMENT_HREF] = element.href;
    }
    return removeEmptyProperties(properties);
  };
  var addAdditionalEventProperties = function (event, type) {
    var baseEvent = {
      event: event,
      timestamp: Date.now(),
      type: type
    };
    if (isElementBasedEvent(baseEvent) && baseEvent.event.target !== null) {
      // Retrieve additional event properties from the target element
      var closestTrackedAncestor = getClosestElement(baseEvent.event.target, options.cssSelectorAllowlist);
      if (closestTrackedAncestor) {
        baseEvent.closestTrackedAncestor = closestTrackedAncestor;
        baseEvent.targetElementProperties = getEventProperties(baseEvent.type, closestTrackedAncestor);
      }
      return baseEvent;
    }
    return baseEvent;
  };
  var setup = function (config, amplitude) {
    return __awaiter(void 0, void 0, void 0, function () {
      var shouldTrackEvent, shouldTrackActionClick, allObservables, clickTrackingSubscription, changeSubscription, actionClickSubscription, allowlist, actionClickAllowlist;
      var _a, _b;
      return __generator(this, function (_c) {
        /* istanbul ignore if */
        if (typeof document === 'undefined') {
          return [2 /*return*/];
        }
        shouldTrackEvent = createShouldTrackEvent(options, options.cssSelectorAllowlist);
        shouldTrackActionClick = createShouldTrackEvent(options, options.actionClickAllowlist);
        allObservables = createObservables();
        clickTrackingSubscription = trackClicks({
          allObservables: allObservables,
          options: options,
          amplitude: amplitude,
          shouldTrackEvent: shouldTrackEvent
        });
        subscriptions.push(clickTrackingSubscription);
        changeSubscription = trackChange({
          allObservables: allObservables,
          getEventProperties: getEventProperties,
          amplitude: amplitude,
          shouldTrackEvent: shouldTrackEvent
        });
        subscriptions.push(changeSubscription);
        actionClickSubscription = trackActionClick({
          allObservables: allObservables,
          options: options,
          getEventProperties: getEventProperties,
          amplitude: amplitude,
          shouldTrackEvent: shouldTrackEvent,
          shouldTrackActionClick: shouldTrackActionClick
        });
        subscriptions.push(actionClickSubscription);
        /* istanbul ignore next */
        (_a = config === null || config === void 0 ? void 0 : config.loggerProvider) === null || _a === void 0 ? void 0 : _a.log("".concat(name, " has been successfully added."));
        // Setup visual tagging selector
        if (window.opener && visualTaggingOptions.enabled) {
          allowlist = options.cssSelectorAllowlist;
          actionClickAllowlist = options.actionClickAllowlist;
          /* istanbul ignore next */
          (_b = visualTaggingOptions.messenger) === null || _b === void 0 ? void 0 : _b.setup(__assign(__assign({
            logger: config === null || config === void 0 ? void 0 : config.loggerProvider
          }, (config === null || config === void 0 ? void 0 : config.serverZone) && {
            endpoint: constants.AMPLITUDE_ORIGINS_MAP[config.serverZone]
          }), {
            isElementSelectable: createShouldTrackEvent(options, __spreadArray(__spreadArray([], __read(allowlist), false), __read(actionClickAllowlist), false)),
            cssSelectorAllowlist: allowlist,
            actionClickAllowlist: actionClickAllowlist
          }));
        }
        return [2 /*return*/];
      });
    });
  };
  var execute = function (event) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, event];
      });
    });
  };
  var teardown = function () {
    return __awaiter(void 0, void 0, void 0, function () {
      var subscriptions_1, subscriptions_1_1, subscription;
      var e_1, _a;
      return __generator(this, function (_b) {
        try {
          for (subscriptions_1 = __values(subscriptions), subscriptions_1_1 = subscriptions_1.next(); !subscriptions_1_1.done; subscriptions_1_1 = subscriptions_1.next()) {
            subscription = subscriptions_1_1.value;
            subscription.unsubscribe();
          }
        } catch (e_1_1) {
          e_1 = {
            error: e_1_1
          };
        } finally {
          try {
            if (subscriptions_1_1 && !subscriptions_1_1.done && (_a = subscriptions_1.return)) _a.call(subscriptions_1);
          } finally {
            if (e_1) throw e_1.error;
          }
        }
        return [2 /*return*/];
      });
    });
  };
  return {
    name: name,
    type: type,
    setup: setup,
    execute: execute,
    teardown: teardown
  };
};
